import React from "react";
import Link from "gatsby-plugin-transition-link";

const Button = ({
  className,
  href,
  outboundLink,
  modal,
  onClick,
  type,
  altStyle,
  icon,
  text,
}) => {
  const Tag = href ? Link : "button";
  const target = outboundLink && "_blank";
  const rel = outboundLink && "noopener noreferrer";
  let link = true;

  href && (link = !href.includes("tel:", "mailto:"));

  return (
    <Tag
      className={`group relative overflow-hidden border border-solid px-6 py-3 min-w-[154px] h-[44px] inline-flex items-center justify-center text-center font-body text-sm font-normal tracking-wider uppercase no-underline whitespace-nowrap hover:shadow-fill-primary ${
        altStyle
          ? "text-primary-400 hover:text-white bg-transparent border-primary-400 outline-primary"
          : "text-primary-400 hover:text-white bg-white border-primary-400 outline-primary"
      } ${className || ""}`}
      {...(link ? { fade: "true", to: href } : { href: href })}
      target={target}
      rel={rel}
      data-modal-open={modal}
      onClick={onClick}
      type={type}
      alt-style={altStyle}
    >
      {icon && (
        <i
          className={`mr-2 z-10 ${
            altStyle ? "text-secondary-400" : "text-white"
          } ${icon}`}
        ></i>
      )}
      <span className="z-10">{text}</span>
      <div className="bg-secondary-200 w-0 group-hover:w-full h-full absolute left-0 rounded-3xl transition-all duration-500 ease-linear"></div>
    </Tag>
  );
};

export default Button;
