import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Link from "gatsby-plugin-transition-link";
import { GatsbyImage } from "gatsby-plugin-image";

import ModalContact from "../Modal/ModalContact";
import nestLogo from "../../images/global/Nest Logo.svg";

const Footer = ({ hideFooter }) => {
  const getYear = () => {
    return new Date().getFullYear();
  };

  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "global/Logo-grayscale.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 187)
        }
      }
      equalHousingOpportunity: file(
        relativePath: { eq: "global/Equal-Housing-Opportunity.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 52)
        }
      }
    }
  `);

  const navigation = {
    resourcesGuides: [
      {
        name: "Compare Loan Programs",
        href: "/home-loan-programs-minnesota/",
      },
      {
        name: "Mortgage Calculators",
        href: "/mortgage-calculator/",
      },
      {
        name: "Mortgage Basics",
        href: "/home-mortgage-basics/",
      },
      {
        name: "Loan Process",
        href: "/loan-process/",
      },
    ],
    ourSpecialties: [
      {
        name: "First-Time Home Buyer",
        href: "/first-time-home-loans-minnesota/",
      },
      {
        name: "Second Home Buyer",
        href: "/second-home-buyer/",
      },
      {
        name: "Move-Up Buyer",
        href: "/move-up-home-buyers/",
      },
      {
        name: "Investor",
        href: "/mortgage-broker-investors/",
      },
    ],
    apply: [
      {
        name: "Request Rates",
        href: "/request-rates/",
      },
    ],
  };

  return (
    <>
      <footer
        className={`bg-primary-100 pt-12 lg:pt-20 pb-8 lg:pb-6 ${
          hideFooter && "hidden"
        }`}
      >
        <div className="container">
          <div className="grid md:grid-cols-12 lg:justify-between space-y-20 lg:space-y-0 lg:space-x-8 mb-16">
            <div className="lg:col-start-1 md:col-span-3">
              <div className="flex items-center justify-center lg:justify-start">
                <Link to="/">
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    alt="Thistle Financial Logo"
                    className="mx-auto lg:mx-0 mb-10 lg:mb-8"
                  />
                </Link>
              </div>

              <div className="flex items-center justify-center lg:justify-start space-x-4">
                <GatsbyImage
                  image={
                    data.equalHousingOpportunity.childImageSharp.gatsbyImageData
                  }
                  alt="Equal-Housing-Opportunity Logo"
                  className=""
                />
                <div className="text-gray-800">
                  <span className="font-bold tracking-wider">NMLS ID</span>
                  <br />
                  <span className="text-sm">#374338</span>
                </div>
              </div>
            </div>

            <div className="lg:col-end-13 md:col-span-9">
              <div className="grid grid-cols-2 gap-x-10 lg:gap-x-0 gap-y-16 lg:gap-y-0 lg:flex lg:justify-end">
                <div className="lg:pr-6 xl:pr-10 lg:border-r lg:border-primary-600/30">
                  <div className="font-heading text-lg text-gray-800 font-bold tracking-wider mb-5">
                    Resources
                  </div>

                  <ul className="flex flex-col space-y-2">
                    {navigation.resourcesGuides.map((item) => (
                      <li key={item.name} className="whitespace-nowrap">
                        <Link
                          to={item.href}
                          className="relative text-sm text-primary-400 md:text-primary-600 pb-px after:absolute after:bg-primary-600 after:h-px after:w-0 hover:after:w-full after:bottom-0 after:left-auto hover:after:left-0 after:right-0 hover:after:right-auto after:transition-all after:duration-300 after:ease-linear no-underline"
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="lg:pl-6 xl:pl-10 lg:pr-6 xl:pr-10 lg:border-r lg:border-primary-600/30">
                  <div className="font-heading text-lg text-gray-800 font-bold tracking-wider mb-5">
                    Specialties
                  </div>

                  <ul className="flex flex-col space-y-2">
                    {navigation.ourSpecialties.map((item) => (
                      <li key={item.name} className="whitespace-nowrap">
                        <Link
                          to={item.href}
                          className="relative text-sm text-primary-400 md:text-primary-600 pb-px after:absolute after:bg-primary-600 after:h-px after:w-0 hover:after:w-full after:bottom-0 after:left-auto hover:after:left-0 after:right-0 hover:after:right-auto after:transition-all after:duration-300 after:ease-linear no-underline"
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="lg:pr-6 xl:pr-10 lg:pl-6 xl:pl-10 lg:border-r lg:border-primary-600/30">
                  <div className="font-heading text-lg text-gray-800 font-bold tracking-wider mb-5">
                    Apply
                  </div>

                  <ul className="flex flex-col space-y-2">
                    {navigation.apply.map((item) => (
                      <li key={item.name} className="whitespace-nowrap">
                        <a
                          href={item.href}
                          className="relative text-sm text-primary-400 md:text-primary-600 pb-px after:absolute after:bg-primary-600 after:h-px after:w-0 hover:after:w-full after:bottom-0 after:left-auto hover:after:left-0 after:right-0 hover:after:right-auto after:transition-all after:duration-300 after:ease-linear no-underline"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                    <li className="whitespace-nowrap">
                      <a
                        href="https://www.blink.mortgage/app/signup/p/thistlefinancialllc/markthistle"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="relative text-sm text-primary-400 md:text-primary-600 pb-px after:absolute after:bg-primary-600 after:h-px after:w-0 hover:after:w-full after:bottom-0 after:left-auto hover:after:left-0 after:right-0 hover:after:right-auto after:transition-all after:duration-300 after:ease-linear no-underline"
                      >
                        Apply Now
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="lg:pl-6 xl:pl-10">
                  <div className="font-heading text-lg text-gray-800 font-bold tracking-wider mb-5">
                    Contact
                  </div>

                  <ul className="flex flex-col space-y-2">
                    {/* <li>
                      <a
                        href="https://goo.gl/maps/G4sZmrRQV3ySMRoe8"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="relative text-sm text-primary-400 md:text-primary-600 pb-px after:absolute after:bg-primary-600 after:h-px after:w-0 hover:after:w-full after:bottom-0 after:left-auto hover:after:left-0 after:right-0 hover:after:right-auto after:transition-all after:duration-300 after:ease-linear no-underline"
                      >
                        11328 Bluestem Lane
                        <br /> Eden Prairie, Minnesota 55347
                      </a>
                    </li> */}
                    <li>
                      <a
                        href="tel:952-944-9000"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="relative text-sm text-primary-400 md:text-primary-600 pb-px after:absolute after:bg-primary-600 after:h-px after:w-0 hover:after:w-full after:bottom-0 after:left-auto hover:after:left-0 after:right-0 hover:after:right-auto after:transition-all after:duration-300 after:ease-linear no-underline"
                      >
                        (952) 944-9000
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap lg:flex-nowrap flex-col lg:flex-row justify-items-center lg:justify-between lg:items-center lg:pt-4">
            <div className="lg:flex lg:items-center mb-8 lg:mb-0">
              <ul className="flex items-center flex-wrap space-x-4 justify-center lg:justify-start">
                <li className="text-xs">© {getYear()} Thistle Financial</li>
                <li className="text-xs">
                  <Link
                    to="/privacy-policy/"
                    className="hover:text-primary-400 no-underline"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li className="text-xs">
                  <Link
                    to="/terms-of-use/"
                    className="hover:text-primary-400 no-underline"
                  >
                    Terms of Use
                  </Link>
                </li>
              </ul>
            </div>

            <div className="text-xs w-full md:w-auto">
              <a
                className="no-underline hover:text-primary-400 flex items-center justify-center lg:justify-start space-x-1"
                href="https://www.wisedigitalpartners.com/affordable-web-design/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Powered by</span> <img src={nestLogo} alt="NEST logo" />
              </a>
            </div>
          </div>
        </div>
      </footer>

      <ModalContact />
    </>
  );
};

export default Footer;
