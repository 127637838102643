import React, { useState, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useOnClickOutside } from "../../hooks";
import Link from "gatsby-plugin-transition-link";
import { GatsbyImage } from "gatsby-plugin-image";

import Burger from "./Burger";
import OffCanvas from "../OffCanvas/OffCanvas";
import ButtonSolid from "../Button/ButtonSolid";
import ButtonGhost from "../Button/ButtonGhost";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const MainNav = ({
  headerStyle,
  headerHasBorder,
  headerLinkColor,
  scrolled,
}) => {
  // determine if offcanvas is open
  const [offcanvasOpen, setOffcanvasOpen] = useState(false);

  const [subMenuHovering1, setSubMenuHovering1] = useState(false);
  const isHoveringSubMenu1 = () => setSubMenuHovering1(true);
  const notHoveringSubMenu1 = () => setSubMenuHovering1(false);

  const [subMenuHovering2, setSubMenuHovering2] = useState(false);
  const isHoveringSubMenu2 = () => setSubMenuHovering2(true);
  const notHoveringSubMenu2 = () => setSubMenuHovering2(false);

  // const [subMenuHovering3, setSubMenuHovering3] = useState(false);
  // const isHoveringSubMenu3 = () => setSubMenuHovering3(true);
  // const notHoveringSubMenu3 = () => setSubMenuHovering3(false);

  // handle click of navigation items
  const clickHandler = () => {
    setOffcanvasOpen(!offcanvasOpen);
  };

  // close offcanvas onclick outside
  const node = useRef();
  useOnClickOutside(node, () => setOffcanvasOpen(false));

  const data = useStaticQuery(graphql`
    {
      darkLogo: file(relativePath: { eq: "global/Logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 195, placeholder: NONE)
        }
      }
      lightLogo: file(relativePath: { eq: "global/Logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 195, placeholder: NONE)
        }
      }
    }
  `);

  // Define logos based on header style
  let initialLogo = null,
    stickyLogo = null,
    className = null;

  if (headerStyle === "overlap" || headerStyle === "overlap-hero") {
    initialLogo = data.lightLogo.childImageSharp.gatsbyImageData;
    stickyLogo = data.darkLogo.childImageSharp.gatsbyImageData;
    className = "absolute";
  } else {
    initialLogo = data.darkLogo.childImageSharp.gatsbyImageData;
    stickyLogo = data.darkLogo.childImageSharp.gatsbyImageData;
  }

  if (offcanvasOpen) {
    initialLogo = data.darkLogo.childImageSharp.gatsbyImageData;
    stickyLogo = data.darkLogo.childImageSharp.gatsbyImageData;
  }

  const navigation = {
    ourSpecialties: [
      {
        name: "First-Time Home Buyer",
        href: "/first-time-home-loans-minnesota/",
      },
      {
        name: "Second Home Buyer",
        href: "/second-home-buyer/",
      },
      {
        name: "Move-Up Buyer",
        href: "/move-up-home-buyers/",
      },
      {
        name: "Investor",
        href: "/mortgage-broker-investors/",
      },
    ],
    resourcesGuides: [
      {
        name: "Compare Loan Programs",
        href: "/home-loan-programs-minnesota/",
      },
      {
        name: "Mortgage Basics",
        href: "/home-mortgage-basics/",
      },
      {
        name: "Loan Process",
        href: "/loan-process/",
      },
    ],
    tools: [
      {
        name: "Mortgage Calculator",
        href: "/mortgage-calculators/",
      },
      {
        name: "Mortgage Calculator",
        href: "/mortgage-calculator/",
      },
      {
        name: "VA Loan Calculator",
        href: "/va-loan-calculator/",
      },
      {
        name: "FHA Loan Calculator",
        href: "/fha-loan-calculator/",
      },
      {
        name: "Home Affordability Calculator",
        href: "/home-affordability-calculator/",
      },
    ],
  };

  return (
    <nav
      id="main-navigation"
      className={`py-2 lg:py-4 bg-white w-full transition duration-300 ease-linear border-b border-solid border-primary-600 ${
        headerStyle === "overlap" ? "lg:bg-transparent" : "lg:bg-white"
      } ${headerHasBorder && "border-b border-solid border-gray-300"} ${
        offcanvasOpen ? "" : ""
      } ${
        scrolled && "!fixed !bg-white top-0 left-0 w-full z-50"
      } ${className}`}
      role="navigation"
      aria-label="main-navigation"
      offcanvasOpen={offcanvasOpen}
    >
      <div className="container relative flex justify-between items-center">
        <div className="flex-auto flex items-center lg:justify-start">
          <Link to="/">
            <div className={`logo-initial ${scrolled && "hidden"}`}>
              <div className="hidden lg:block">
                <GatsbyImage
                  image={initialLogo}
                  alt="Thistle Financial Logo"
                  className="w-[145px] md:w-[195px]"
                />
              </div>
              <div className="lg:hidden">
                <GatsbyImage
                  image={stickyLogo}
                  alt="Thistle Financial Logo"
                  className="w-[145px] md:w-[195px]"
                />
              </div>
            </div>
            <div className={`logo-fixed hidden ${scrolled && "!block"}`}>
              <GatsbyImage
                image={stickyLogo}
                alt="Thistle Financial Logo"
                className="w-[145px] md:w-[195px]"
              />
            </div>
          </Link>
        </div>
        <div className="flex items-center justify-end flex-auto">
          <ul
            id="navigation-desktop"
            className="hidden lg:flex lg:flex-row lg:space-x-8 lg:items-center lg:justify-end lg:mr-8"
          >
            <li className={`group relative ${subMenuHovering2 && "active"}`}>
              <Link
                to="/buying-home-minnesota/"
                onMouseEnter={isHoveringSubMenu2}
                onMouseLeave={notHoveringSubMenu2}
                className={`font-body uppercase text-sm tracking-wider pb-8 ${
                  scrolled && "text-gray-900"
                } ${
                  headerLinkColor === "white"
                    ? "text-white hover:text-white"
                    : "text-gray-900 hover:text-primary-400"
                }`}
              >
                Purchase
              </Link>
              <ul className="absolute top-0 bg-gray-600 shadow-3xl flex flex-col space-y-4 w-auto invisible group-hover:visible transform -translate-x-8 translate-y-20 group-hover:translate-y-14 opacity-0 group-hover:opacity-100 px-8 pt-10 pb-8 z-10 transition-all duration-300 ease-linear">
                <div className="text-white/50 uppercase text-sm tracking-wider mb-1">
                  Our Specialties
                </div>
                {navigation.ourSpecialties.map((item) => (
                  <li key={item.name} className="whitespace-nowrap">
                    <Link
                      to={item.href}
                      className="relative block font-body text-white hover:text-primary-400"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}

                <div className="text-white/50 uppercase text-sm tracking-wider pt-1 mb-1">
                  Resources & Guides
                </div>
                {navigation.resourcesGuides.map((item) => (
                  <li key={item.name} className="whitespace-nowrap">
                    <Link
                      to={item.href}
                      className="relative block font-body text-white hover:text-primary-400"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            <li className="group">
              <Link
                to="/refinance-minnesota/"
                className={`font-body uppercase text-sm tracking-wider ${
                  scrolled && "text-gray-900"
                } ${
                  headerLinkColor === "white"
                    ? "text-white hover:text-white"
                    : "text-gray-900 hover:text-primary-400"
                }`}
              >
                Refinance
              </Link>
            </li>

            <li className={`group relative ${subMenuHovering1 && "active"}`}>
              <Link
                to="/mortgage-calculators/"
                onMouseEnter={isHoveringSubMenu1}
                onMouseLeave={notHoveringSubMenu1}
                className={`font-body uppercase text-sm tracking-wider pb-8 ${
                  scrolled && "text-gray-900"
                } ${
                  headerLinkColor === "white"
                    ? "text-white hover:text-white"
                    : "text-gray-900 hover:text-primary-400"
                }`}
              >
                Tools
              </Link>
              <ul className="absolute top-0 bg-gray-600 shadow-3xl flex flex-col space-y-4 w-auto invisible group-hover:visible transform -translate-x-8 translate-y-20 group-hover:translate-y-14 opacity-0 group-hover:opacity-100 px-8 pt-10 pb-8 z-10 transition-all duration-300 ease-linear">
                {navigation.tools.slice(1).map((item) => (
                  <li key={item.name} className="whitespace-nowrap">
                    <Link
                      to={item.href}
                      className="relative block font-body text-white hover:text-primary-400"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            <li className="group">
              <Link
                to="/about-us/"
                className={`font-body uppercase text-sm tracking-wider ${
                  scrolled && "text-gray-900"
                } ${
                  headerLinkColor === "white"
                    ? "text-white hover:text-white"
                    : "text-gray-900 hover:text-primary-400"
                }`}
              >
                About
              </Link>
            </li>
          </ul>

          <div className="hidden lg:inline-flex lg:items-center lg:space-x-4">
            <ButtonGhost modal="modal-contact" text="Contact" />
            <ButtonSolid href="/request-rates/" text="Request Rates" />
          </div>

          <div className="lg:hidden" ref={node}>
            <Burger
              offcanvasOpen={offcanvasOpen}
              setOffcanvasOpen={setOffcanvasOpen}
              headerStyle={headerStyle}
              scrolled={scrolled}
              aria-controls="offcanvas-navigation"
            />
            <OffCanvas offcanvasOpen={offcanvasOpen} id="offcanvas-navigation">
              <div className="max-w-[258px] mx-auto">
                <ul id="navigation-mobile" className="mb-12 text-center">
                  <Accordion
                    allowZeroExpanded={true}
                    className="flex flex-col space-y-6"
                  >
                    <li>
                      <AccordionItem uuid={1}>
                        <AccordionItemButton className="flex items-center justify-center focus:outline-none">
                          <p className="font-heading text-4xl text-white hover:text-white font-medium no-underline mb-0">
                            Purchase
                          </p>
                        </AccordionItemButton>

                        <AccordionItemPanel className="pt-6 max-w-4xl">
                          <ul className="flex flex-col space-y-4">
                            <div className="text-white uppercase text-sm tracking-wider mb-1">
                              Our Specialties
                            </div>
                            {navigation.ourSpecialties.map((item) => (
                              <li key={item.name} className="whitespace-nowrap">
                                <Link
                                  to={item.href}
                                  className="relative block font-body text-white hover:text-primary-400"
                                >
                                  {item.name}
                                </Link>
                              </li>
                            ))}

                            <div className="text-white uppercase text-sm tracking-wider pt-1 mb-1">
                              Resources & Guides
                            </div>
                            {navigation.resourcesGuides.map((item) => (
                              <li key={item.name} className="whitespace-nowrap">
                                <Link
                                  to={item.href}
                                  onKeyDown={clickHandler}
                                  onClick={clickHandler}
                                  className="relative block font-body text-white hover:text-primary-400"
                                >
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </li>

                    <li>
                      <Link
                        to="/refinance-minnesota/"
                        onKeyDown={clickHandler}
                        onClick={clickHandler}
                        className="font-heading text-4xl text-white hover:text-white font-medium no-underline"
                      >
                        Refinance
                      </Link>
                    </li>

                    <li>
                      <AccordionItem uuid={2}>
                        <AccordionItemButton className="flex items-center justify-center focus:outline-none">
                          <p className="font-heading text-4xl text-white hover:text-white font-medium no-underline mb-0">
                            Tools
                          </p>
                        </AccordionItemButton>

                        <AccordionItemPanel className="pt-6 max-w-4xl">
                          <ul className="flex flex-col space-y-4">
                            {navigation.tools.map((item) => (
                              <li key={item.name} className="whitespace-nowrap">
                                <Link
                                  to={item.href}
                                  onKeyDown={clickHandler}
                                  onClick={clickHandler}
                                  className="relative block font-body text-white hover:text-primary-400"
                                >
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </li>

                    <li>
                      <Link
                        to="/about-us/"
                        onKeyDown={clickHandler}
                        onClick={clickHandler}
                        className="font-heading text-4xl text-white hover:text-white font-medium no-underline"
                      >
                        About
                      </Link>
                    </li>
                  </Accordion>
                </ul>

                <div className="flex justify-center space-x-4">
                  <ButtonGhost modal="modal-contact" text="Contact" />
                  <ButtonSolid href="/request-rates/" text="Request Rates" />
                </div>
              </div>
            </OffCanvas>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default MainNav;
